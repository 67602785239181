import { useEffect, useState } from 'react'

import { Close } from '@mui/icons-material'
import { Box, Button, IconButton, Modal } from '@mui/material'
import { useRouter } from 'next/router'
import { FormattedMessage, useIntl } from 'react-intl'

import { useAuth, useUsername } from 'contexts/authentication'

import styles from './UserResearchPanel.module.css'

export const UserResearchPanelModal = () => {
    const router = useRouter()
    const { isAuthenticated, loginWithRedirect } = useAuth()
    const intl = useIntl()

    const username = useUsername()

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsOpen(router.query.userResearchPanel === 'true')
    }, [router.query.userResearchPanel])

    const handleClose = () => {
        router.back()
    }

    const login = () => {
        loginWithRedirect({
            appState: {
                returnTo: '/account/user-research-panel/signup',
            },
        })
    }

    const signup = () => {
        router.push('/account/user-research-panel/signup')
    }

    const getFooterMessage = () => {
        if (!isAuthenticated) {
            return intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Before signing up to the User Research Panel, you must first login with or create an Avalance Canada account.',
            })
        }

        return intl.formatMessage(
            {
                description: 'User Research Panel',
                defaultMessage:
                    'You are logged in as <strong>{username}</strong>. Signing up will add you to the Avalanche Canada User Research Panel. Preferences can then be managed in your account settings.',
            },
            {
                username,
                strong: chunks => <strong>{chunks}</strong>,
            }
        )
    }

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Box className={styles.Modal}>
                <div className={styles.CloseButton}>
                    <IconButton onClick={handleClose} aria-label="close">
                        <Close />
                    </IconButton>
                </div>
                <div className={styles.Content}>
                    <h2>
                        <FormattedMessage description="User Research Panel" defaultMessage="Welcome!" />
                    </h2>
                    <p>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="At Avalanche Canada, we are committed to continually developing effective products that serve backcountry users and keep us on the cutting edge of global public avalanche safety. To do this, we need your help. With support from the <a>Avalanche Research Program</a> at Simon Fraser University (SFU), we are working on creating a community of backcountry users to share their experiences and insights with us. The information provided by the research panel will directly help us to improve how our products and services keep people safer in the mountains.
"
                            values={{
                                a: chunks => <a href="https://avalancheresearch.ca/">{chunks}</a>,
                            }}
                        />
                    </p>
                    <h2>
                        <FormattedMessage description="User Research Panel" defaultMessage="Who Should Join?" />
                    </h2>
                    <p>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="You should! We're looking for a group that accurately represents the people who choose to travel in avalanche terrain in winter. We need users who can represent all the kinds of people who use the backcountry—that means hearing from people from all experience levels and who take part in all types of backcountry winter sports. Whether you check the forecast every day or you've never opened an avalanche forecast, we're equally interested in what you have to say."
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="We know that everyone has different goals and considerations when they travel in the mountains. By providing information about what you do out there and how you prepare, you can help us to make sure our products work for you. Anything you choose to share with us will only be used to help us understand what Avalanche Canada could be doing better."
                        />
                    </p>
                    <h2>
                        <FormattedMessage description="User Research Panel" defaultMessage="Why Join?" />
                    </h2>
                    <ul>
                        <li>
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="Help to improve and shape the future of public avalanche safety products and services"
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="Learn more about avalanche safety and the new products we are creating"
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="Be eligible for prizes"
                            />
                        </li>
                    </ul>
                    <h2>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="What To Expect if You Sign Up"
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="People involved in this project will receive regular emails from Avalanche Canada with invitations to participate in short studies, such as surveys or interviews. You don’t have to participate in every study and you can opt out at any time."
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="If you’re interested in taking part, click the “Sign Up” button below to complete the registration survey. <strong>It typically takes 10-30 minutes to complete</strong>, with more experienced participants needing slightly more time to enter their information. You will be able to access this registration information in your account settings, in case you run out of time or want to update your answers."
                            values={{
                                strong: chunks => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="We’re grateful to everyone who gets involved. Your insights will help us make changes that can make backcountry safer for everyone."
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Before signing up to the User Research Panel, you must first login with or create an Avalanche Canada account."
                        />
                    </p>
                </div>
                <div className={styles.Footer}>
                    <div>{getFooterMessage()}</div>
                    <div className={styles.ButtonContainer}>
                        <Button variant="outlined" color="primary" onClick={handleClose}>
                            <FormattedMessage description="User Research Panel" defaultMessage="Cancel" />
                        </Button>
                        <ActionButton login={login} signup={signup} />
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

type ActionButtonProps = {
    login: () => void
    signup: () => void
}

const ActionButton = ({ login, signup }: ActionButtonProps) => {
    const { isAuthenticated } = useAuth()

    if (!isAuthenticated) {
        return (
            <Button variant="contained" color="primary" onClick={login}>
                <FormattedMessage description="User Research Panel" defaultMessage="Login" />
            </Button>
        )
    }

    return (
        <Button variant="contained" color="primary" onClick={signup}>
            <FormattedMessage description="User Research Panel" defaultMessage="Sign Up" />
        </Button>
    )
}
